// import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  Hidden,
  // Link,
  Tooltip,
  Typography,
  Container,
  // Alert
} from '@mui/material';
import { experimentalStyled } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars-2';

import Logo from 'src/components/Logo';
import ContentWrapper from 'src/components/ContentWrapper';
import JWTLogin from '../LoginJWT';

import { useAuth, useProgram } from 'src/hooks';

const icons = {
  Auth0: '/static/images/logo/auth0.svg',
  FirebaseAuth: '/static/images/logo/firebase.svg',
  JWT: '/static/images/logo/jwt.svg',
};

const Content = experimentalStyled(Box)(
  () => `
    display: flex;
    height: 100%;
    flex: 1;
`
);

const MainContent = experimentalStyled(Box)(
  () => `
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
`
);

const SidebarWrapper = experimentalStyled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.white[100]};
    width: 440px;
`
);

const SidebarContent = experimentalStyled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: ${theme.spacing(6)};
`
);

const CardImg = experimentalStyled(Card)(
  ({ theme }) => `
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid ${theme.colors.alpha.black[10]};
    transition: ${theme.transitions.create(['border'])};

    &:hover {
      border-color: ${theme.colors.primary.main};
    }
`
);

const TypographyH1 = experimentalStyled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(33)};
`
);

function LoginCover() {
  const { program } = useProgram();

  return (
    <ContentWrapper title="Login - Cover">
      <Content>
        <Hidden mdDown>
          <SidebarWrapper>
            <Scrollbars autoHide>
              <SidebarContent>
                <Logo />
                <Box mt={6}>
                  <TypographyH1 variant="h1" sx={{ mb: 7 }}>
                    {program.name}
                  </TypographyH1>

                  <Tooltip arrow placement="top" title="Auth0">
                    <CardImg sx={{ width: 80, height: 80, top: -50 }}>
                      <img width={40} alt="Auth0" src={icons['Auth0']} />
                    </CardImg>
                  </Tooltip>
                  <Tooltip arrow placement="top" title="Firebase">
                    <CardImg sx={{ width: 90, height: 90, right: -15 }}>
                      <img
                        width={50}
                        alt="Firebase"
                        src={icons['FirebaseAuth']}
                      />
                    </CardImg>
                  </Tooltip>
                  <Tooltip arrow placement="top" title="JSON Web Token">
                    <CardImg
                      sx={{ width: 120, height: 120, top: -30, right: -30 }}
                    >
                      <img width={80} alt="JSON Web Token" src={icons['JWT']} />
                    </CardImg>
                  </Tooltip>
                </Box>
              </SidebarContent>
            </Scrollbars>
          </SidebarWrapper>
        </Hidden>
        <MainContent>
          <Container maxWidth="sm">
            <Card sx={{ mt: 3, px: 4, py: 5 }}>
              <Box textAlign="center">
                <Typography variant="h2" sx={{ mb: 1 }}>
                  {/* {t('Sign in')} */}
                  Ingresar
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{ mb: 3 }}
                >
                  Accede a la administración de tu programa con tu usuario y
                  contraseña
                </Typography>
              </Box>
              <JWTLogin />
            </Card>
          </Container>
        </MainContent>
      </Content>
    </ContentWrapper>
  );
}

export default LoginCover;
