import { Zoom } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import { es } from 'date-fns/locale';

import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit';
import ScrollToTop from './utils/ScrollToTop';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SnackbarCloseButton } from './components/SnackbarClose';

import { ApiProvider, ProgramProvider, ApiAuthProvider } from 'src/contexts';

function App() {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
        <SnackbarProvider
          maxSnack={50}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          hideIconVariant
          autoHideDuration={4000}
          TransitionComponent={Zoom}
          action={(key) => <SnackbarCloseButton snackbarKey={key} />}
        >
          <ApiProvider>
            <ProgramProvider>
              <ScrollToTop />
              <CssBaseline />
              <AuthProvider>
                <ApiAuthProvider>
                  <AppInit />
                </ApiAuthProvider>
              </AuthProvider>
            </ProgramProvider>
          </ApiProvider>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
