import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Components

const homeRoutes = [
  {
    path: '',
    element: <Navigate to="users" replace />,
  },
  // {
  //   path: "participants",
  //   element: <Participants />,
  // },
  // {
  //   path: "bills",
  //   children: [
  //     {
  //       path: "list",
  //       element: <Bills />,
  //     },
  //     {
  //       path: "create",
  //       element: <CreateBill />,
  //     },
  //   ],
  // },
];

export default homeRoutes;
