import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

import { useProgram } from 'src/hooks';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  const { program } = useProgram();

  return (
    <LogoWrapper to="/">
      <img src={program.logo} />
    </LogoWrapper>
  );
}

export default Logo;
