import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import { useApi, useProgram } from 'src/hooks';
import { Participant, UserRole } from '../utils/types';
// import { Participant, SystemUser } from 'src/models/participant';

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  participant: Participant | null;
  token: string | null;
}

interface AuthContextValue extends AuthState {
  method: 'JWT';
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    participant: Participant | null;
    token: string | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    participant: Participant;
    token: string;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    participant: Participant;
  };
};

type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  participant: null,
  token: null,
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessTokenParticipantAdmin', accessToken);
  } else {
    localStorage.removeItem('accessTokenParticipantAdmin');
  }
};

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, participant, token } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      participant,
      token,
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { participant, token } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      participant,
      token,
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    participant: null,
    token: null,
  }),
  REGISTER: (state: AuthState, action: RegisterAction): AuthState => {
    const { participant } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      participant,
    };
  },
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { get, post } = useApi();
  const { program } = useProgram();

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem(
          'accessTokenParticipantAdmin'
        );

        if (accessToken) {
          setSession(accessToken);

          const participant = await get<Participant>('/participants/info/me');

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              participant,
              token: accessToken,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              participant: null,
              token: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            participant: null,
            token: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (username: string, password: string): Promise<void> => {
    const accessTokenResponse = await post<{
      accessToken: string;
    }>('/auth/participant/login', {
      username,
      password,
      programId: program?.id,
    });

    const accessToken = accessTokenResponse.accessToken;
    setSession(accessToken);

    const participant = await get<Participant>('/participants/info/me');

    dispatch({
      type: 'LOGIN',
      payload: {
        participant,
        token: accessToken,
      },
    });
  };

  const logout = async (): Promise<void> => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
