import { Box } from '@mui/material';
import { useRoutes } from 'react-router-dom';

import Logo from 'src/components/LogoSign';
import router from 'src/router';

import { useProgram } from 'src/hooks';

function AppInit() {
  const content = useRoutes(router);
  const { program } = useProgram();

  if (!program) {
    return (
      <Box
        sx={{
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          width: '100%',
          height: '100%',
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Logo />
      </Box>
    );
  }

  return content;
}

export default AppInit;
