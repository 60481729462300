import type { ReactNode } from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

import { useAuth } from 'src/hooks';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems = (): MenuItems[] => {
  const { participant } = useAuth();

  return [
    {
      heading: 'Inicio',
      items: [
        ...(participant.isAConsumerRegistrar
          ? [
              {
                name: 'Consumidores',
                icon: DinnerDiningIcon,
                link: '/home/consumers',
                items: [
                  {
                    name: 'Listar',
                    link: '/home/consumers/list',
                  },
                  {
                    name: 'Registrar',
                    link: '/home/consumers/create',
                  },
                ],
              },
            ]
          : []),
        {
          name: 'Tus dependientes',
          icon: SupervisedUserCircleIcon,
          link: '/home/dependents',
        },
      ],
    },
  ];
};

const menuItems2: MenuItems[] = [
  {
    heading: 'Inicio',
    items: [
      {
        name: 'Tablero',
        icon: DashboardIcon,
        link: '/home/admin-dashboard',
      },
    ],
  },
];

export default menuItems;
