import { useContext } from 'react';

import { ApiAuthContext } from 'src/contexts';

export function useApiAuth() {
  const context = useContext(ApiAuthContext);

  if (!context)
    throw new Error('useApiAuth should be used inside ApiAuthProvider');

  return context;
}
