import { createContext, FC, ReactNode, useReducer, useEffect } from 'react';

import { useApi } from 'src/hooks';
import { config } from 'src/utils/config';
import { Program } from 'src/utils/types';

interface ProgramState {
  status: ActionType.IDLE;
  program: Program;
  coinName: string | null;
}

interface ProgramContextValue extends ProgramState {}

interface ProgramProviderProps {
  children: ReactNode;
}

export enum ActionType {
  IDLE = 'IDLE',
  GET_PROGRAM = 'GET_PROGRAM',
  ERROR = 'ERROR',
}

type GetProgramAction = {
  type: ActionType.GET_PROGRAM;
  payload: {
    program: Program;
  };
};

type Action = GetProgramAction;

const initialState: ProgramState = {
  status: ActionType.IDLE,
  program: null,
  coinName: null,
};

const reducer = (state: ProgramState, action: Action): ProgramState => {
  switch (action.type) {
    case ActionType.GET_PROGRAM:
      const { program } = action.payload;
      return {
        ...state,
        program,
        coinName: `${program.coinName
          .charAt(0)
          .toUpperCase()}${program.coinName.slice(1)}`,
      };
    default:
      return state;
  }
};

export const ProgramContext = createContext<ProgramContextValue>({
  ...initialState,
});

export const ProgramProvider: FC<ProgramProviderProps> = ({ children }) => {
  const [programState, dispatch] = useReducer(reducer, initialState);
  const { program } = config;
  const { get } = useApi();

  const getProgram = async () => {
    try {
      const response = await get<Program>(`/programs/store/${program.id}`);
      dispatch({
        type: ActionType.GET_PROGRAM,
        payload: {
          program: response,
        },
      });
    } catch (e) {
      console.error('getProgram() ->', e);
    }
  };

  useEffect(() => {
    getProgram();
  }, []);

  return (
    <ProgramContext.Provider value={{ ...programState }}>
      {children}
    </ProgramContext.Provider>
  );
};
